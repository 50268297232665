import React, {useEffect, useRef, useState} from "react"
import { MDBDataTable } from "mdbreact"
import {Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Label, Input, FormGroup, Button} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import {AvField, AvForm} from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import Multiselect from 'multiselect-react-dropdown';
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "../../firebase";
import ReactPlayer from "react-player";

const TVShow = () => {
    const [allBeneficiaries, setAllBeneficiaries] = useState([])
    const [myBeneficiaries, setMyBeneficiaries] = useState([])
    const [dataRows, setDataRows] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [data, setData] = useState({})
    const [nickname, setNickname] = useState("")
    const [accountName, setAccountName] = useState("")
    const [accountNumber, setAccountNumber] = useState("")
    const [accountType, setAccountType] = useState("")
    const [editedSuccessfully, setEditedSuccessfully] = useState(false)
    const [editFailed, setEditFailed] = useState(false)
    const [createdSuccessfully, setCreatedSuccessfully] = useState(false)
    const [createFailed, setCreateFailed] = useState(false)
    const [deletedSuccessfully, setDeletedSuccessfully] = useState(false)
    const [deleteFailed, setDeleteFailed] = useState(false)
    const [title, setTitle] = useState("")
    const [genres, setGenres] = useState([])
    const [selectedGenres, setSelectedGenres] = useState([])
    const [tags, setTags] = useState([])
    const [spokenLanguages, setSpokenLanguages] = useState([])
    const [subtitlePath, setSubtitlePath] = useState("")
    const [subtitleProgress, setSubtitleProgress] = useState("")
    const [trailerPath, setTrailerPath] = useState("")
    const [trailerProgress, setTrailerProgress] = useState("")
    const [videoPath, setVideoPath] = useState("")
    const [videoProgress, setVideoProgress] = useState("")
    const [posterPath, setPosterPath] = useState("")
    const [posterProgress, setPosterProgress] = useState("")
    const [featuredPosterPath, setFeaturedPosterPath] = useState("")
    const [featuredPosterProgress, setFeaturedPosterProgress] = useState("")
    const [originalLanguage, setOriginalLanguage] = useState("")
    const [releaseDate, setReleaseDate] = useState(null)
    const [status, setStatus] = useState(null)
    const [runtime, setRuntime] = useState("")
    const [tagline, setTagline] = useState("")
    const [overview, setOverview] = useState("")
    const [showFileModal, setShowFileModal] = useState(false)
    const [fileShown, setFileShown] = useState("")
    const [movieId, setMovieId] = useState("")
    const [loading, setLoading] = useState(false)

    const editFormRef = useRef();
    const createFormRef = useRef();

    const columns = [
        {
            label: "Title",
            field: "title",
            sort: "asc",
            width: 150,
        },
        {
            label: "Release Date",
            field: "releaseDate",
            sort: "asc",
            width: 270,
        },
        {
            label: "Action",
            field: "action",
            width: 200,
        },
    ]

    const toggleEditModal = () => setShowEditModal(!showEditModal)
    const toggleCreateModal = () => setShowCreateModal(!showCreateModal)
    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)

    const toggleFileModal = () => setShowFileModal(!showFileModal)

    const handleFeaturedPosterSubmit = (e) => {
        e.preventDefault()
        console.log({e})
        const file = e.target?.files[0]
        console.log({file})
        if (!file) return;
        const storageRef = ref(storage, `tvshows/posters/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setFeaturedPosterProgress(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setFeaturedPosterPath(downloadURL)
                });
            }
        );
    }
    const handlePosterSubmit = (e) => {
        e.preventDefault()
        console.log({e})
        const file = e.target?.files[0]
        console.log({file})
        if (!file) return;
        const storageRef = ref(storage, `tvshows/posters/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setPosterProgress(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setPosterPath(downloadURL)
                });
            }
        );
    }

    const handleTrailerSubmit = (e) => {
        e.preventDefault()
        console.log({e})
        const file = e.target?.files[0]
        console.log({file})
        if (!file) return;
        const storageRef = ref(storage, `tvshows/trailers/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setTrailerProgress(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // console.log({downloadURL})
                    setTrailerPath(downloadURL)
                });
            }
        );
    }

    const handleMovieSubmit = (e) => {
        e.preventDefault()
        console.log({e})
        const file = e.target?.files[0]
        console.log({file})
        if (!file) return;
        const storageRef = ref(storage, `tvshows/videos/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setVideoProgress(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // console.log({downloadURL})
                    setVideoPath(downloadURL)
                });
            }
        );
    }

    const handleSubtitleSubmit = (e) => {
        e.preventDefault()
        console.log({e})
        const file = e.target?.files[0]
        console.log({file})
        if (!file) return;
        const storageRef = ref(storage, `tvshows/subtitles/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setSubtitleProgress(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    // console.log({downloadURL})
                    setSubtitlePath(downloadURL)
                });
            }
        );
    }

    const viewFile = (type) => {
        setFileShown(type)
        toggleFileModal();
    }


    useEffect(() => {
        fetch(`https://faithx.mudiaga.com.ng/api/all-shows/1/1000`)
            .then(response => response.json())
            .then(response => {
                let res = response
                res?.data?.docs?.forEach((item, index) => {
                    item.releaseDate = new Date(item?.releaseDate).toDateString()
                    item.id = (
                        <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.id}</div>
                    );
                    item.action = (
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div
                                className="uil-trash-alt"
                                style={{
                                    cursor: "pointer",
                                    color: "white",
                                    fontSize: ".7em",
                                    padding: ".5rem",
                                    borderRadius: ".3rem",
                                    background: "#2f4395",
                                    width: "45%",
                                    textAlign: "center",
                                    height: 30,
                                }}
                                onClick={() => selectMovieToEdit(item?._id)}
                            >
                                Edit
                            </div>
                            <div
                                className="uil-trash-alt"
                                style={{
                                    cursor: "pointer",
                                    color: "white",
                                    fontSize: ".7em",
                                    padding: ".5rem",
                                    borderRadius: ".3rem",
                                    background: "#fb6262",
                                    width: "45%",
                                    textAlign: "center",
                                    height: 30,
                                }}
                                onClick={() => selectMovieToDelete(item?._id)}
                            >
                                Delete
                            </div>
                        </div>
                    );
                })
                console.log({res})
                // let myDocs = res?.data?.docs?.filter(el => el?.email === JSON.parse(localStorage.getItem("authUser"))?.email)
                let myDocs = res?.data?.docs
                myDocs = myDocs?.filter(el => el?.isDeleted !== true )
                const obj = JSON.parse(localStorage.getItem("authUser"))
                myDocs = myDocs?.filter(el => el?.user === obj?._id)
                setMyBeneficiaries(myDocs)
                setAllBeneficiaries(response?.data?.docs)
                setData({
                    columns,
                    rows: myDocs
                })
            })
            .catch(error => {
                console.log({error})
            })
    },[])

    useEffect(() => {
        fetch(`https://faithx.mudiaga.com.ng/api/all-genres/1/1000`)
            .then(res => res.json())
            .then(res => {
                console.log({res})
                setGenres(res?.data?.docs)
            })
    },[])

    useEffect(() => {
        console.log({genres})
    },[genres])

    async function handleValidSubmit(event, values) {
        setLoading(true)
        const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(values)
        let toPost = {
            user: obj?._id,
            overview: values?.tagline,
            originalLanguage: values?.originalLanguage,
            releaseDate: values?.releaseDate,
            trailerPath,
            posterPath,
            featuredPosterPath,
            genres: selectedGenres,
            title: values?.title,
        }
        try {
            const response = await fetch("https://faithx.mudiaga.com.ng/api/show/edit", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id: movieId,
                    payload: toPost,
                }),
            });

            const result = await response.json();
            setLoading(false)
            setEditedSuccessfully(true)
            // console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setLoading(false)
            setEditFailed(true)
        }
    }


    async function handleValidCreate(event, values) {
        setLoading(true)
        const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(values)
        let toPost = {
            user: obj?._id,
            overview: values?.tagline,
            originalLanguage: values?.originalLanguage,
            releaseDate: values?.releaseDate,
            trailerPath,
            posterPath,
            featuredPosterPath,
            genres: selectedGenres,
            title: values?.title,
        }
        try {
            const response = await fetch("https://faithx.mudiaga.com.ng/api/show/new", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toPost),
            });

            const result = await response.json();

            // add the movie to the selected genres
            for (const genre of selectedGenres) {
                console.log({genre})
                const index = genres.indexOf(genre);
                let genreId = genre;
                let movieId = result?._id
                console.log({movieId})
                console.log({genreId})

                const response2 = await fetch("https://faithx.mudiaga.com.ng/api/genre/add-show", {
                // const response2 = await fetch("https://faithx.mudiaga.com.ng/api/genre/add-movie", {
                    method: "POST", // or 'PUT'
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({id: genreId, movie: movieId}),
                });

                const addedMovie = await response2.json();
                console.log({addedMovie})
            }

            setLoading(false)
            setCreatedSuccessfully(true)
            console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setLoading(false)
            setCreateFailed(true)
        }
    }

    async function handleDeleteMovie(id) {
        setLoading(true)
        const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(id)
        let toPost = {
            id,
            payload: { isDeleted: true }
        }
        try {
            const response = await fetch("https://faithx.mudiaga.com.ng/api/show/edit", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toPost),
            });

            const result = await response.json();
            setLoading(false)
            setDeletedSuccessfully(true)
            // console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setLoading(false)
            setDeleteFailed(true)
        }
    }

    const selectMovieToEdit = (id) => {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        fetch(`https://faithx.mudiaga.com.ng/api/show/${id}`)
            .then(res => res.json())
            .then(res => {
                // console.log("nickname: ", new Date(res?.data?.releaseDate))
                setTitle(res?.data?.title)
                setMovieId(res?.data?._id)
                setTagline(res?.data?.overview)
                setPosterPath(res?.data?.posterPath)
                setTrailerPath(res?.data?.trailerPath)
                setSubtitlePath([res?.data?.subtitlePath])
                setSelectedGenres(res?.data?.genres)
                setOriginalLanguage(res?.data?.originalLanguage)
                setReleaseDate(new Date(res?.data?.releaseDate))
                toggleEditModal()
            })
            .catch(err => {
                console.log({err})
                toggleEditModal()
            })
        // setNickname()
    }

    const selectMovieToDelete = (id) => {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        fetch(`https://faithx.mudiaga.com.ng/api/show/${id}`)
            .then(res => res.json())
            .then(res => {
                // console.log("nickname: ", res?.data)
                setTitle(res?.data?.title)
                setMovieId(res?.data?._id)
                setRuntime(res?.data?.runtime)
                setTagline(res?.data?.overview)
                setPosterPath(res?.data?.posterPath)
                setVideoPath(res?.data?.videoPath)
                setTrailerPath(res?.data?.trailerPath)
                setSubtitlePath([res?.data?.subtitlePath])
                setGenres(res?.data?.genres)
                setOriginalLanguage(res?.data?.originalLanguage)
                setReleaseDate(new Date(res?.data?.releaseDate))
                toggleDeleteModal()
            })
            .catch(err => {
                console.log({err})
                toggleDeleteModal()
            })
        // setNickname()
    }

    const playMovie = (id) => {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        fetch(`https://faithx.mudiaga.com.ng/api/movies/${id}`)
            .then(res => res.json())
            .then(res => {
                console.log("Video Path: ", res?.data)
                setTitle(res?.data?.title)
                setRuntime(res?.data?.runtime)
                setTagline(res?.data?.tagline)
                setPosterPath(res?.data?.posterPath)
                setVideoPath(res?.data?.videoPath)
                setTrailerPath(res?.data?.trailerPath)
                setSubtitlePath(res?.data?.subtitlePath)
                setGenres(res?.data?.genres)
                setOriginalLanguage(res?.data?.originalLanguage)
                setFileShown("movie")
                toggleFileModal()
            })
            .catch(err => {
                console.log({err})
                toggleDeleteModal()
            })
        // setNickname()
    }

    const onSelect = (selectedList, selectedItem) => {
        // console.log({selectedItem})
        // console.log({selectedList})
        let genresList = selectedList?.map(el => el?._id)
        // console.log({genresList})
        setSelectedGenres(genresList)
    }

    const onEditSelect = (selectedList, selectedItem) => {
        // console.log({selectedItem})
        // console.log({selectedList})
        // let genresList = selectedList?.map(el => el?._id)
        // console.log({genresList})
        setSelectedGenres(selectedList)
    }

    const onRemove = (selectedList, removedItem) => {
        let genresList = selectedList?.map(el => el?._id)
        // console.log({genresList})
        setSelectedGenres(genresList)
    }

    const onEditRemove = (selectedList, removedItem) => {
        // let genresList = selectedList?.map(el => el?._id)
        // console.log({genresList})
        setSelectedGenres(selectedList)
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="FaithX" breadcrumbItem="TV Shows" />

                <Row>
                    <Col>
                        <button className="btn btn-primary mb-3 float-end"
                        onClick={toggleCreateModal}>
                            Create a New TV Show
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <MDBDataTable responsive bordered striped data={data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            // centered
                            fullscreen
                            isOpen={showCreateModal}
                            toggle={() => {
                                toggleEditModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    New Show
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleCreateModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    disabled={loading}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <AvForm
                                    ref={createFormRef}
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidCreate(e, v)
                                    }}
                                >
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="title"
                                                    label="Show Title"
                                                    value={title}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="genres">Genres</label>
                                                <Multiselect
                                                    options={genres} // Options to display in the dropdown
                                                    selectedValues={""} // Preselected value to persist in dropdown
                                                    onSelect={onSelect} // Function will trigger on select event
                                                    onRemove={onRemove} // Function will trigger on remove event
                                                    displayValue="title" // Property name to display in the dropdown options
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <Row style={{columnGap:0}}>
                                                    <Col xs={9}>
                                                        <AvField
                                                            name="featuredPosterPath"
                                                            label="Movie Poster (Large,landscape)"
                                                            value={""}
                                                            className="form-control"
                                                            placeholder=""
                                                            type="file"
                                                            required
                                                            onChange={handleFeaturedPosterSubmit}
                                                        />
                                                        {
                                                            !featuredPosterPath &&
                                                            <div className='outerbar'>
                                                                <div className='innerbar' style={{ width: `${featuredPosterProgress}%` }}>{featuredPosterProgress}%</div>
                                                            </div>
                                                        }
                                                    </Col>
                                                    <Col xs={2} className="mt-1">
                                                        <Button className="mt-4"
                                                                onClick={() => viewFile("poster")}>
                                                            View
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField type="select" name="originalLanguage" label="Movie Language"
                                                         value={originalLanguage}>
                                                    <option>Select a language</option>
                                                    <option value="af">Afrikaans</option>
                                                    <option value="sq">Albanian - shqip</option>
                                                    <option value="am">Amharic - አማርኛ</option>
                                                    <option value="ar">Arabic - العربية</option>
                                                    <option value="an">Aragonese - aragonés</option>
                                                    <option value="hy">Armenian - հայերեն</option>
                                                    <option value="ast">Asturian - asturianu</option>
                                                    <option value="az">Azerbaijani - azərbaycan dili</option>
                                                    <option value="eu">Basque - euskara</option>
                                                    <option value="be">Belarusian - беларуская</option>
                                                    <option value="bn">Bengali - বাংলা</option>
                                                    <option value="bs">Bosnian - bosanski</option>
                                                    <option value="br">Breton - brezhoneg</option>
                                                    <option value="bg">Bulgarian - български</option>
                                                    <option value="ca">Catalan - català</option>
                                                    <option value="ckb">Central Kurdish - کوردی (دەستنوسی عەرەبی)</option>
                                                    <option value="zh">Chinese - 中文</option>
                                                    <option value="zh-HK">Chinese (Hong Kong) - 中文（香港）</option>
                                                    <option value="zh-CN">Chinese (Simplified) - 中文（简体）</option>
                                                    <option value="zh-TW">Chinese (Traditional) - 中文（繁體）</option>
                                                    <option value="co">Corsican</option>
                                                    <option value="hr">Croatian - hrvatski</option>
                                                    <option value="cs">Czech - čeština</option>
                                                    <option value="da">Danish - dansk</option>
                                                    <option value="nl">Dutch - Nederlands</option>
                                                    <option value="en">English</option>
                                                    <option value="en-AU">English (Australia)</option>
                                                    <option value="en-CA">English (Canada)</option>
                                                    <option value="en-IN">English (India)</option>
                                                    <option value="en-NZ">English (New Zealand)</option>
                                                    <option value="en-ZA">English (South Africa)</option>
                                                    <option value="en-GB">English (United Kingdom)</option>
                                                    <option value="en-US">English (United States)</option>
                                                    <option value="eo">Esperanto - esperanto</option>
                                                    <option value="et">Estonian - eesti</option>
                                                    <option value="fo">Faroese - føroyskt</option>
                                                    <option value="fil">Filipino</option>
                                                    <option value="fi">Finnish - suomi</option>
                                                    <option value="fr">French - français</option>
                                                    <option value="fr-CA">French (Canada) - français (Canada)</option>
                                                    <option value="fr-FR">French (France) - français (France)</option>
                                                    <option value="fr-CH">French (Switzerland) - français (Suisse)</option>
                                                    <option value="gl">Galician - galego</option>
                                                    <option value="ka">Georgian - ქართული</option>
                                                    <option value="de">German - Deutsch</option>
                                                    <option value="de-AT">German (Austria) - Deutsch (Österreich)</option>
                                                    <option value="de-DE">German (Germany) - Deutsch (Deutschland)</option>
                                                    <option value="de-LI">German (Liechtenstein) - Deutsch (Liechtenstein)</option>
                                                    <option value="de-CH">German (Switzerland) - Deutsch (Schweiz)</option>
                                                    <option value="el">Greek - Ελληνικά</option>
                                                    <option value="gn">Guarani</option>
                                                    <option value="gu">Gujarati - ગુજરાતી</option>
                                                    <option value="ha">Hausa</option>
                                                    <option value="haw">Hawaiian - ʻŌlelo Hawaiʻi</option>
                                                    <option value="he">Hebrew - עברית</option>
                                                    <option value="hi">Hindi - हिन्दी</option>
                                                    <option value="hu">Hungarian - magyar</option>
                                                    <option value="is">Icelandic - íslenska</option>
                                                    <option value="id">Indonesian - Indonesia</option>
                                                    <option value="ia">Interlingua</option>
                                                    <option value="ga">Irish - Gaeilge</option>
                                                    <option value="it">Italian - italiano</option>
                                                    <option value="it-IT">Italian (Italy) - italiano (Italia)</option>
                                                    <option value="it-CH">Italian (Switzerland) - italiano (Svizzera)</option>
                                                    <option value="ja">Japanese - 日本語</option>
                                                    <option value="kn">Kannada - ಕನ್ನಡ</option>
                                                    <option value="kk">Kazakh - қазақ тілі</option>
                                                    <option value="km">Khmer - ខ្មែរ</option>
                                                    <option value="ko">Korean - 한국어</option>
                                                    <option value="ku">Kurdish - Kurdî</option>
                                                    <option value="ky">Kyrgyz - кыргызча</option>
                                                    <option value="lo">Lao - ລາວ</option>
                                                    <option value="la">Latin</option>
                                                    <option value="lv">Latvian - latviešu</option>
                                                    <option value="ln">Lingala - lingála</option>
                                                    <option value="lt">Lithuanian - lietuvių</option>
                                                    <option value="mk">Macedonian - македонски</option>
                                                    <option value="ms">Malay - Bahasa Melayu</option>
                                                    <option value="ml">Malayalam - മലയാളം</option>
                                                    <option value="mt">Maltese - Malti</option>
                                                    <option value="mr">Marathi - मराठी</option>
                                                    <option value="mn">Mongolian - монгол</option>
                                                    <option value="ne">Nepali - नेपाली</option>
                                                    <option value="no">Norwegian - norsk</option>
                                                    <option value="nb">Norwegian Bokmål - norsk bokmål</option>
                                                    <option value="nn">Norwegian Nynorsk - nynorsk</option>
                                                    <option value="oc">Occitan</option>
                                                    <option value="or">Oriya - ଓଡ଼ିଆ</option>
                                                    <option value="om">Oromo - Oromoo</option>
                                                    <option value="ps">Pashto - پښتو</option>
                                                    <option value="fa">Persian - فارسی</option>
                                                    <option value="pl">Polish - polski</option>
                                                    <option value="pt">Portuguese - português</option>
                                                    <option value="pt-BR">Portuguese (Brazil) - português (Brasil)</option>
                                                    <option value="pt-PT">Portuguese (Portugal) - português (Portugal)</option>
                                                    <option value="pa">Punjabi - ਪੰਜਾਬੀ</option>
                                                    <option value="qu">Quechua</option>
                                                    <option value="ro">Romanian - română</option>
                                                    <option value="mo">Romanian (Moldova) - română (Moldova)</option>
                                                    <option value="rm">Romansh - rumantsch</option>
                                                    <option value="ru">Russian - русский</option>
                                                    <option value="gd">Scottish Gaelic</option>
                                                    <option value="sr">Serbian - српски</option>
                                                    <option value="sh">Serbo-Croatian - Srpskohrvatski</option>
                                                    <option value="sn">Shona - chiShona</option>
                                                    <option value="sd">Sindhi</option>
                                                    <option value="si">Sinhala - සිංහල</option>
                                                    <option value="sk">Slovak - slovenčina</option>
                                                    <option value="sl">Slovenian - slovenščina</option>
                                                    <option value="so">Somali - Soomaali</option>
                                                    <option value="st">Southern Sotho</option>
                                                    <option value="es">Spanish - español</option>
                                                    <option value="es-AR">Spanish (Argentina) - español (Argentina)</option>
                                                    <option value="es-419">Spanish (Latin America) - español (Latinoamérica)</option>
                                                    <option value="es-MX">Spanish (Mexico) - español (México)</option>
                                                    <option value="es-ES">Spanish (Spain) - español (España)</option>
                                                    <option value="es-US">Spanish (United States) - español (Estados Unidos)</option>
                                                    <option value="su">Sundanese</option>
                                                    <option value="sw">Swahili - Kiswahili</option>
                                                    <option value="sv">Swedish - svenska</option>
                                                    <option value="tg">Tajik - тоҷикӣ</option>
                                                    <option value="ta">Tamil - தமிழ்</option>
                                                    <option value="tt">Tatar</option>
                                                    <option value="te">Telugu - తెలుగు</option>
                                                    <option value="th">Thai - ไทย</option>
                                                    <option value="ti">Tigrinya - ትግርኛ</option>
                                                    <option value="to">Tongan - lea fakatonga</option>
                                                    <option value="tr">Turkish - Türkçe</option>
                                                    <option value="tk">Turkmen</option>
                                                    <option value="tw">Twi</option>
                                                    <option value="uk">Ukrainian - українська</option>
                                                    <option value="ur">Urdu - اردو</option>
                                                    <option value="ug">Uyghur</option>
                                                    <option value="uz">Uzbek - o‘zbek</option>
                                                    <option value="vi">Vietnamese - Tiếng Việt</option>
                                                    <option value="wa">Walloon - wa</option>
                                                    <option value="cy">Welsh - Cymraeg</option>
                                                    <option value="fy">Western Frisian</option>
                                                    <option value="xh">Xhosa</option>
                                                    <option value="yi">Yiddish</option>
                                                    <option value="yo">Yoruba - Èdè Yorùbá</option>
                                                    <option value="zu">Zulu - isiZulu</option>
                                                </AvField>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <Row style={{columnGap:0}}>
                                                    <Col xs={9}>
                                                        <AvField
                                                            name="posterPath"
                                                            label="Movie Poster (Small, 400x600)"
                                                            value={""}
                                                            className="form-control"
                                                            placeholder=""
                                                            type="file"
                                                            required
                                                            onChange={handlePosterSubmit}
                                                        />
                                                        {
                                                            !posterPath &&
                                                            <div className='outerbar'>
                                                                <div className='innerbar' style={{ width: `${posterProgress}%` }}>{posterProgress}%</div>
                                                            </div>
                                                        }
                                                    </Col>
                                                    <Col xs={2} className="mt-1">
                                                        <Button className="mt-4"
                                                        onClick={() => viewFile("poster")}>
                                                            View
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <Row>
                                                    <Col xs={9}>
                                                        <AvField
                                                            name="trailerPath"
                                                            label="Trailer"
                                                            value={""}
                                                            className="form-control"
                                                            placeholder=""
                                                            type="file"
                                                            required
                                                            onChange={handleTrailerSubmit}
                                                        />
                                                        {
                                                            !trailerPath &&
                                                            <div className='outerbar'>
                                                                <div className='innerbar' style={{ width: `${trailerProgress}%` }}>{trailerProgress}%</div>
                                                            </div>
                                                        }
                                                    </Col>
                                                    <Col xs={2} className="mt-1">
                                                        <Button className="mt-4"
                                                        onClick={() => viewFile("trailer")}>
                                                            View
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="releaseDate"
                                                    label="Release Date"
                                                    value={releaseDate}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="date"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="tagline"
                                                    label="Overview"
                                                    value={tagline}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="textarea"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </AvForm>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleCreateModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                    disabled={loading}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => createFormRef?.current?.handleSubmit()}
                                    disabled={loading}
                                >
                                    {
                                        loading? "Saving...": "Save New Show"
                                    }
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showEditModal}
                            toggle={() => {
                                toggleEditModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Edit TV Show
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleEditModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    disabled={loading}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <AvForm
                                    ref={editFormRef}
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v)
                                    }}
                                >
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="title"
                                                    label="Movie Title"
                                                    value={title}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="genres">Genres</label>
                                                <Multiselect
                                                    options={genres} // Options to display in the dropdown
                                                    selectedValues={selectedGenres} // Preselected value to persist in dropdown
                                                    onSelect={onEditSelect} // Function will trigger on select event
                                                    onRemove={onEditRemove} // Function will trigger on remove event
                                                    displayValue="title" // Property name to display in the dropdown options
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="tagline"
                                                    label="Tagline"
                                                    value={tagline}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField type="select" name="originalLanguage" label="Movie Language"
                                                         value={originalLanguage}>
                                                    <option>Select a language</option>
                                                    <option value="af">Afrikaans</option>
                                                    <option value="sq">Albanian - shqip</option>
                                                    <option value="am">Amharic - አማርኛ</option>
                                                    <option value="ar">Arabic - العربية</option>
                                                    <option value="an">Aragonese - aragonés</option>
                                                    <option value="hy">Armenian - հայերեն</option>
                                                    <option value="ast">Asturian - asturianu</option>
                                                    <option value="az">Azerbaijani - azərbaycan dili</option>
                                                    <option value="eu">Basque - euskara</option>
                                                    <option value="be">Belarusian - беларуская</option>
                                                    <option value="bn">Bengali - বাংলা</option>
                                                    <option value="bs">Bosnian - bosanski</option>
                                                    <option value="br">Breton - brezhoneg</option>
                                                    <option value="bg">Bulgarian - български</option>
                                                    <option value="ca">Catalan - català</option>
                                                    <option value="ckb">Central Kurdish - کوردی (دەستنوسی عەرەبی)</option>
                                                    <option value="zh">Chinese - 中文</option>
                                                    <option value="zh-HK">Chinese (Hong Kong) - 中文（香港）</option>
                                                    <option value="zh-CN">Chinese (Simplified) - 中文（简体）</option>
                                                    <option value="zh-TW">Chinese (Traditional) - 中文（繁體）</option>
                                                    <option value="co">Corsican</option>
                                                    <option value="hr">Croatian - hrvatski</option>
                                                    <option value="cs">Czech - čeština</option>
                                                    <option value="da">Danish - dansk</option>
                                                    <option value="nl">Dutch - Nederlands</option>
                                                    <option value="en">English</option>
                                                    <option value="en-AU">English (Australia)</option>
                                                    <option value="en-CA">English (Canada)</option>
                                                    <option value="en-IN">English (India)</option>
                                                    <option value="en-NZ">English (New Zealand)</option>
                                                    <option value="en-ZA">English (South Africa)</option>
                                                    <option value="en-GB">English (United Kingdom)</option>
                                                    <option value="en-US">English (United States)</option>
                                                    <option value="eo">Esperanto - esperanto</option>
                                                    <option value="et">Estonian - eesti</option>
                                                    <option value="fo">Faroese - føroyskt</option>
                                                    <option value="fil">Filipino</option>
                                                    <option value="fi">Finnish - suomi</option>
                                                    <option value="fr">French - français</option>
                                                    <option value="fr-CA">French (Canada) - français (Canada)</option>
                                                    <option value="fr-FR">French (France) - français (France)</option>
                                                    <option value="fr-CH">French (Switzerland) - français (Suisse)</option>
                                                    <option value="gl">Galician - galego</option>
                                                    <option value="ka">Georgian - ქართული</option>
                                                    <option value="de">German - Deutsch</option>
                                                    <option value="de-AT">German (Austria) - Deutsch (Österreich)</option>
                                                    <option value="de-DE">German (Germany) - Deutsch (Deutschland)</option>
                                                    <option value="de-LI">German (Liechtenstein) - Deutsch (Liechtenstein)</option>
                                                    <option value="de-CH">German (Switzerland) - Deutsch (Schweiz)</option>
                                                    <option value="el">Greek - Ελληνικά</option>
                                                    <option value="gn">Guarani</option>
                                                    <option value="gu">Gujarati - ગુજરાતી</option>
                                                    <option value="ha">Hausa</option>
                                                    <option value="haw">Hawaiian - ʻŌlelo Hawaiʻi</option>
                                                    <option value="he">Hebrew - עברית</option>
                                                    <option value="hi">Hindi - हिन्दी</option>
                                                    <option value="hu">Hungarian - magyar</option>
                                                    <option value="is">Icelandic - íslenska</option>
                                                    <option value="id">Indonesian - Indonesia</option>
                                                    <option value="ia">Interlingua</option>
                                                    <option value="ga">Irish - Gaeilge</option>
                                                    <option value="it">Italian - italiano</option>
                                                    <option value="it-IT">Italian (Italy) - italiano (Italia)</option>
                                                    <option value="it-CH">Italian (Switzerland) - italiano (Svizzera)</option>
                                                    <option value="ja">Japanese - 日本語</option>
                                                    <option value="kn">Kannada - ಕನ್ನಡ</option>
                                                    <option value="kk">Kazakh - қазақ тілі</option>
                                                    <option value="km">Khmer - ខ្មែរ</option>
                                                    <option value="ko">Korean - 한국어</option>
                                                    <option value="ku">Kurdish - Kurdî</option>
                                                    <option value="ky">Kyrgyz - кыргызча</option>
                                                    <option value="lo">Lao - ລາວ</option>
                                                    <option value="la">Latin</option>
                                                    <option value="lv">Latvian - latviešu</option>
                                                    <option value="ln">Lingala - lingála</option>
                                                    <option value="lt">Lithuanian - lietuvių</option>
                                                    <option value="mk">Macedonian - македонски</option>
                                                    <option value="ms">Malay - Bahasa Melayu</option>
                                                    <option value="ml">Malayalam - മലയാളം</option>
                                                    <option value="mt">Maltese - Malti</option>
                                                    <option value="mr">Marathi - मराठी</option>
                                                    <option value="mn">Mongolian - монгол</option>
                                                    <option value="ne">Nepali - नेपाली</option>
                                                    <option value="no">Norwegian - norsk</option>
                                                    <option value="nb">Norwegian Bokmål - norsk bokmål</option>
                                                    <option value="nn">Norwegian Nynorsk - nynorsk</option>
                                                    <option value="oc">Occitan</option>
                                                    <option value="or">Oriya - ଓଡ଼ିଆ</option>
                                                    <option value="om">Oromo - Oromoo</option>
                                                    <option value="ps">Pashto - پښتو</option>
                                                    <option value="fa">Persian - فارسی</option>
                                                    <option value="pl">Polish - polski</option>
                                                    <option value="pt">Portuguese - português</option>
                                                    <option value="pt-BR">Portuguese (Brazil) - português (Brasil)</option>
                                                    <option value="pt-PT">Portuguese (Portugal) - português (Portugal)</option>
                                                    <option value="pa">Punjabi - ਪੰਜਾਬੀ</option>
                                                    <option value="qu">Quechua</option>
                                                    <option value="ro">Romanian - română</option>
                                                    <option value="mo">Romanian (Moldova) - română (Moldova)</option>
                                                    <option value="rm">Romansh - rumantsch</option>
                                                    <option value="ru">Russian - русский</option>
                                                    <option value="gd">Scottish Gaelic</option>
                                                    <option value="sr">Serbian - српски</option>
                                                    <option value="sh">Serbo-Croatian - Srpskohrvatski</option>
                                                    <option value="sn">Shona - chiShona</option>
                                                    <option value="sd">Sindhi</option>
                                                    <option value="si">Sinhala - සිංහල</option>
                                                    <option value="sk">Slovak - slovenčina</option>
                                                    <option value="sl">Slovenian - slovenščina</option>
                                                    <option value="so">Somali - Soomaali</option>
                                                    <option value="st">Southern Sotho</option>
                                                    <option value="es">Spanish - español</option>
                                                    <option value="es-AR">Spanish (Argentina) - español (Argentina)</option>
                                                    <option value="es-419">Spanish (Latin America) - español (Latinoamérica)</option>
                                                    <option value="es-MX">Spanish (Mexico) - español (México)</option>
                                                    <option value="es-ES">Spanish (Spain) - español (España)</option>
                                                    <option value="es-US">Spanish (United States) - español (Estados Unidos)</option>
                                                    <option value="su">Sundanese</option>
                                                    <option value="sw">Swahili - Kiswahili</option>
                                                    <option value="sv">Swedish - svenska</option>
                                                    <option value="tg">Tajik - тоҷикӣ</option>
                                                    <option value="ta">Tamil - தமிழ்</option>
                                                    <option value="tt">Tatar</option>
                                                    <option value="te">Telugu - తెలుగు</option>
                                                    <option value="th">Thai - ไทย</option>
                                                    <option value="ti">Tigrinya - ትግርኛ</option>
                                                    <option value="to">Tongan - lea fakatonga</option>
                                                    <option value="tr">Turkish - Türkçe</option>
                                                    <option value="tk">Turkmen</option>
                                                    <option value="tw">Twi</option>
                                                    <option value="uk">Ukrainian - українська</option>
                                                    <option value="ur">Urdu - اردو</option>
                                                    <option value="ug">Uyghur</option>
                                                    <option value="uz">Uzbek - o‘zbek</option>
                                                    <option value="vi">Vietnamese - Tiếng Việt</option>
                                                    <option value="wa">Walloon - wa</option>
                                                    <option value="cy">Welsh - Cymraeg</option>
                                                    <option value="fy">Western Frisian</option>
                                                    <option value="xh">Xhosa</option>
                                                    <option value="yi">Yiddish</option>
                                                    <option value="yo">Yoruba - Èdè Yorùbá</option>
                                                    <option value="zu">Zulu - isiZulu</option>
                                                </AvField>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <Row style={{columnGap:0}}>
                                                    <Col xs={9}>
                                                        <AvField
                                                            name="posterPath"
                                                            label="Movie Poster"
                                                            value={""}
                                                            className="form-control"
                                                            placeholder=""
                                                            type="file"
                                                            onChange={handlePosterSubmit}
                                                        />
                                                        {
                                                            !posterPath &&
                                                            <div className='outerbar'>
                                                                <div className='innerbar' style={{ width: `${posterProgress}%` }}>{posterProgress}%</div>
                                                            </div>
                                                        }
                                                    </Col>
                                                    <Col xs={2} className="mt-1">
                                                        <Button className="mt-4"
                                                                onClick={() => viewFile("poster")}>
                                                            View
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <Row>
                                                    <Col xs={9}>
                                                        <AvField
                                                            name="trailerPath"
                                                            label="Trailer"
                                                            value={""}
                                                            className="form-control"
                                                            placeholder=""
                                                            type="file"
                                                            onChange={handleTrailerSubmit}
                                                        />
                                                        {
                                                            !trailerPath &&
                                                            <div className='outerbar'>
                                                                <div className='innerbar' style={{ width: `${trailerProgress}%` }}>{trailerProgress}%</div>
                                                            </div>
                                                        }
                                                    </Col>
                                                    <Col xs={2} className="mt-1">
                                                        <Button className="mt-4"
                                                                onClick={() => viewFile("trailer")}>
                                                            View
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="releaseDate"
                                                    label="Release Date"
                                                    value={releaseDate}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="date"
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </AvForm>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleEditModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                    disabled={loading}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => editFormRef?.current?.handleSubmit()}
                                    disabled={loading}
                                >
                                    {
                                        loading? "Saving...": "Save changes"
                                    }
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showDeleteModal}
                            toggle={() => {
                                toggleDeleteModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Delete TV Show
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleDeleteModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    // disabled={loading}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this TV Show?</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleDeleteModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                >
                                    No
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => handleDeleteMovie(movieId)}
                                >
                                    Yes, Delete
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showFileModal}
                            toggle={() => {
                                toggleFileModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    View File
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleFileModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body text-center">
                                {
                                    fileShown === "poster" &&
                                    <img src={posterPath} alt='uploaded poster' width={300}
                                         height={300} style={{resize: "stretch"}}/>
                                }
                                {
                                    fileShown === "trailer" &&
                                    // <ReactPlayer url={trailerPath} />
                                    <video width="750" height="500" controls>
                                        <source src={trailerPath} />
                                    </video>
                                }
                                {
                                    fileShown === "movie" &&
                                    // <ReactPlayer url={trailerPath} />
                                    <video width="750" height="500" controls>
                                        <source src={videoPath} />
                                    </video>
                                }
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleFileModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                {editedSuccessfully ? (
                    <SweetAlert
                        title="Edit successful"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setEditedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        Your movie has been updated!
                    </SweetAlert>
                ) : null}
                {editFailed ? (
                    <SweetAlert
                        title="Error in editing movie"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setEditFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
                {deletedSuccessfully ? (
                    <SweetAlert
                        title="Delete successful"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setDeletedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        Your show has been deleted!
                    </SweetAlert>
                ) : null}
                {deleteFailed ? (
                    <SweetAlert
                        title="Error in deleting movie"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setDeleteFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
                {createdSuccessfully ? (
                    <SweetAlert
                        title="Show Successfully added"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setCreatedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        A new tv show has been added!
                    </SweetAlert>
                ) : null}
                {createFailed ? (
                    <SweetAlert
                        title="Error in adding movie"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setCreateFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
            </div>

        </React.Fragment>
    )
}

export default TVShow
