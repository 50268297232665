import PropTypes from "prop-types"
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import {CheckCircle, Visibility} from "@mui/icons-material";
import { storage } from '../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import FileViewer from 'react-file-viewer';

const Register = props => {

  const [firstName, setFirstName] = useState("")
  const [values, setValues] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [ssn, setSSN] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [personalFormValidation, setPersonalFormValidation] = useState({});
  const [currentStep, setCurrentStep] = useState(0)
  const [nationality, setNationality] = useState('')
  const [country, setCountry] = useState('')
  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);

  const passwordVisibility = useRef(false)

  const toggle = () => setModal(!modal);
  const toggleError = () => setErrorModal(!errorModal);



  // const options = useMemo(() => countryList().getData(), [])

  // const nationalityHandler = value => {
  //   setNationality(value)
  // }
  //
  // const countryHandler = value => {
  //   setNationality(value)
  // }

  const validatePersonalInformation = () => {
    let validation = {}
    if (firstName?.length < 2 ){
      validation.firstName = false
    }

    if (lastName === ""){
      validation.lastName = false;
    }

    setPersonalFormValidation(validation)

    let result = true

    console.log({validation})

    for (let values in validation){
      result = result && values
    }

    return {result, validation};

  }

  const multistepRef = useRef();

  const StepOne = props => {

    return (<div className="p-2">
      <Form
          className="form-horizontal"
      >
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-6 com-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="firstName">First Name</Label>
                    <Input
                        type="firstName"
                        name="firstName"
                        id="firstName"
                        placeholder="Enter given name"
                        defaultValue={values?.firstName}
                    />
                    {/*{firstName < 2 && <p>Mudiiiii</p>}*/}
                    {/*<FormFeedback>*/}
                    {/*  First Name is compulsory.*/}
                    {/*</FormFeedback>*/}
                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Input
                        type="text"
                        name="latName"
                        id="lastName"
                        placeholder="Enter your family name"
                        defaultValue={values?.lastName}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        defaultValue={values?.email}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="phoneNumber">Phone Number (with country code)</Label>
                    <Input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="Enter your phone number"
                        defaultValue={values?.phoneNumber}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <InputGroup>
                      <Input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Enter a password"
                          defaultValue={values?.password}
                      />
                      {/*<InputGroupText onClick={() => {passwordVisibility.current = true}}>*/}
                      {/*  <Visibility />*/}
                      {/*</InputGroupText>*/}
                    </InputGroup>

                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="confirmPassword">Confirm Password</Label>
                    <InputGroup>
                      <Input
                          type={showConfirmPassword? "text" : "password"}
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder="Re-type your password"
                          defaultValue={values?.confirmPassword}
                      />
                      {/*<InputGroupText onClick={() => {setShowConfirmPassword(prevState => !prevState)}}>*/}
                      {/*  <Visibility />*/}
                      {/*</InputGroupText>*/}
                    </InputGroup>

                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Form>

    </div>)
  }

  const StepTwo = props => {

    return (<div className="p-2">
      <Form
          className="form-horizontal"
      >
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="lastName">Logo</Label><br/>
                    <Input
                        type="file"
                        name="logo"
                        id="logo"
                        onChange={handleFileSubmit}
                    />
                  </FormGroup>
                  {
                      !imgUrl &&
                      <div className='outerbar'>
                        <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
                      </div>
                  }
                  {
                      imgUrl &&
                      <img src={imgUrl} alt='uploaded file' width={200}
                           height={200} style={{resize: "stretch"}}/>
                    // <FileViewer
                    //     fileType={'png'}
                    //     filePath={imgUrl}
                    //     // errorComponent={CustomErrorComponent}
                    //     // onError={this.onError}/>
                    //   />
                  }
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label htmlFor="country">Production/Company Name</Label>
                    {/*<Select id="country" options={options} value={country} onChange={countryHandler} />*/}
                    <Input
                        type="text"
                        name="companyName"
                        id="companyName"
                        placeholder="Enter the name of your company"
                        defaultValue={values?.companyName}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="firstName">Country</Label>
                    <Input
                        type="select"
                        name="country"
                        id="country"
                        // defaultValue={values?.country}
                    >
                      <option value="">Select a country</option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Åland Islands">Åland Islands</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                      <option value="Botswana">Botswana</option>
                      <option value="Bouvet Island">Bouvet Island</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                      <option value="Brunei Darussalam">Brunei Darussalam</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">Central African Republic</option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote D'ivoire">Cote D'ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">Dominican Republic</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">Equatorial Guinea</option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Territories">French Southern Territories</option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-bissau">Guinea-bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                      <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                      <option value="Korea, Republic of">Korea, Republic of</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macao">Macao</option>
                      <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                      <option value="Moldova, Republic of">Moldova, Republic of</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="Netherlands Antilles">Netherlands Antilles</option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russian Federation">Russian Federation</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Helena">Saint Helena</option>
                      <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                      <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-leste">Timor-leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">United Arab Emirates</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Viet Nam">Viet Nam</option>
                      <option value="Virgin Islands, British">Virgin Islands, British</option>
                      <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                      <option value="Wallis and Futuna">Wallis and Futuna</option>
                      <option value="Western Sahara">Western Sahara</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </Input>
                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="phoneNumber">Incorporation Number</Label>
                    <Input
                        type="text"
                        name="inNo"
                        id="inNo"
                        placeholder="Enter your incorporation number"
                        defaultValue={values?.inNo}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="firstName">Website/URL</Label>
                    <Input
                        type="text"
                        name="webUrl"
                        id="webUrl"
                        placeholder="Enter your company's official website"
                        defaultValue={values?.webUrl}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="phoneNumber">Official Email</Label>
                    <Input
                        type="text"
                        name="contactEmail"
                        id="contactEmail"
                        placeholder="Enter your company's official email address"
                        defaultValue={values?.contactEmail}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Form>

    </div>)
  }

  const onNextClick = async (e) => {
    e.preventDefault()

    let isValid = validatePersonalInformation()
    console.log({isValid})

    if (currentStep == 0) {
      // setModal(true);

      let fn = document?.getElementById('firstName').value
      let ln = document?.getElementById('lastName').value
      let em = document?.getElementById('email').value
      let pn = document?.getElementById('phoneNumber').value
      let pw = document?.getElementById('password').value
      let cpw = document?.getElementById('confirmPassword').value

      let k = {
        ...values, ...{
          firstName: fn,
          lastName: ln,
          email: em,
          phone: pn,
          password: pw,
          confirmPassword: cpw
        }
      }
      setValues(k);

      console.log({k})
    } else if (currentStep === 1) {
      let cn = document.getElementById('companyName').value
      let logo = document.getElementById('logo').value
      let country = document.getElementById('country').value
      let inNo = document.getElementById('inNo').value
      let webUrl = document.getElementById('webUrl').value
      let contactEmail = document.getElementById('contactEmail').value

      let k = {
        ...values, ...{
          name: cn,
          logo,
          originCountry: country,
          registrationNumber: inNo,
          webUrl,
          contactEmail,
          logoPath: imgUrl,
        }
      }

      setValues(k)

      console.log({k})

      try {
        // create user
        const response = await fetch("https://faithx.mudiaga.com.ng/api/register", {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(k),
        });

        const user = await response.json();
        console.log("Success:", user);

        k = {
          ...k, ...{
            user: user?._id,
          }
        }

        console.log("New K: ", k)

        // //create production company
        const response2 = await fetch("https://faithx.mudiaga.com.ng/api/company/new", {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(k),
        });

        let comp = await response2.json()
        console.log("Production Company: ", comp)

        setModal(true)
      } catch (error) {
        console.error("Error:", error);
        setErrorModal(true)
      }
    }


    // alert(document?.getElementById('firstName').value)

    if (currentStep < 1) {
      setCurrentStep(prevState => prevState + 1)
    }
  }

  const onPreviousClick = (e) => {
    e.preventDefault()

    if (currentStep == 0){
      let fn = document?.getElementById('firstName').value
      let ln = document?.getElementById('lastName').value
      let em = document?.getElementById('email').value
      // let nn = document?.getElementById('nationality').value
      // let ssn = document?.getElementById('ssn').value
      let pn = document?.getElementById('phoneNumber').value
      let pw = document?.getElementById('password').value
      let cpw = document?.getElementById('confirmPassword').value

      let k ={...values, ...{
          firstName: fn,
          lastName: ln,
          email: em,
          // country: nn,
          // socialSecurityNumber: ssn,
          phone: pn,
          password: pw,
          confirmPassword: cpw
        }}
      setValues(k);

      console.log({k})
    }
    else if (currentStep === 1){
      // let ct  = document.getElementById('country').value
      let cn = document.getElementById('companyName').value
      let logo = document.getElementById('logo').value
      let country = document.getElementById('country').value
      let inNo = document.getElementById('inNo').value
      let webUrl = document.getElementById('webUrl').value
      let contactEmail = document.getElementById('contactEmail').value

      let k = {
        ...values, ...{
          name: cn,
          logo,
          originCountry: country,
          registrationNumber: inNo,
          webUrl,
          contactEmail
        }
      }

      setValues(k)

      console.log({k})
    }

    if (currentStep > 0){
      setCurrentStep(prevState => prevState - 1)
    }
  }

  const handleFileSubmit = (e) => {
    e.preventDefault()
    console.log({e})
    const file = e.target?.files[0]
    console.log({file})
    if (!file) return;
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
        (snapshot) => {
          const progress =
              Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgresspercent(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL)
          });
        }
    );
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={10} xl={10}>
              <Card className="overflow-hidden">
                <div className="card-body pt-5">
                  <div className="row mb-4">
                    <div className="col register-step-header" style={{ fontSize: 18, fontWeight: '800', color: 'black'}}>
                      1. Personal Information
                      {<CheckCircle style={{color: '#33c3f0', marginLeft: 10}} />}
                    </div>
                    <div className="col" style={{fontSize: 18, fontWeight: '800', color: 'black'}}>
                      2. Company Information
                      {currentStep >= 1 && <CheckCircle style={{color: '#33c3f0', marginLeft: 10}} />}
                    </div>
                  </div>
                  {
                    currentStep ===0 && <StepOne />
                  }
                  {
                      currentStep ===1 && <StepTwo />
                  }
                  <Modal isOpen={modal} toggle={toggle} centered={true}>
                    <ModalHeader toggle={toggle}>FaithX</ModalHeader>
                    <ModalBody>
                      User signup successful. A verification email has been sent to your email.
                      Please verify your account to continue.
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={() => window.location.href = "/login"}>
                        Ok
                      </Button>{' '}
                    </ModalFooter>
                  </Modal>

                  <Modal isOpen={errorModal} toggle={toggleError} centered={true}>
                    <ModalHeader toggle={toggle}>FaithX</ModalHeader>
                    <ModalBody>
                      Error in signing up user. Please try again.
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary">
                        Ok
                      </Button>{' '}
                    </ModalFooter>
                  </Modal>

                  <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-6">
                      <div className="row">
                        <div className="col-sm-6">
                          <button className="btn"
                                  style={{backgroundColor: currentStep > 0? '#3b5de7': '#e0e0e0', width: "100%",
                                    color: currentStep > 0? "white": "black", height: 50, borderColor: currentStep > 0? '#3b5de7': "#e0e0e0",
                                    marginRight: 0, marginBottom: 10  }}
                          onClick={onPreviousClick}>
                            Previous
                          </button>
                        </div>
                        <div className="col-sm-6">
                          <button className="btn"
                                  style={{backgroundColor: '#3b5de7', width: "100%", color: "white", height: 50, borderColor: '#3b5de7',
                                    marginBottom: 10}}
                          onClick={onNextClick}>
                            {currentStep == 1? "Submit": "Next"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      By registering you agree to the FaithX{" "}
                      <Link to="#" className="text-primary">
                        Terms of Use
                      </Link>
                    </p>
                  </div>
                </div>
              </Card>
              <div className="mt-5 text-center">
                <p>Already have an account ? <a href="/login" className="fw-medium text-primary">
                  Login</a> </p>
                <p>© {new Date().getFullYear()} FaithX
                        </p>
              </div>
            </Col>
          </Row>
          </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
