import React, {useEffect, useState} from "react"
import {Card, CardBody, CardHeader, CardText, CardTitle, Col, Row} from "reactstrap"


const Dashboard = () => {
  const [productionName, setProductionName] = useState("")
  const [numberOfMovies, setNumberOfMovies] = useState("")

  useEffect(() => {
    let res = localStorage.getItem("companyInfo")
    console.log({res})
    setProductionName(JSON.parse(res)?.name)
    setNumberOfMovies(JSON.parse(res)?.movies?.length)
  },[])


  return (
    <React.Fragment>
      <div className="page-content">

        <Row>
          <div className="col-12 mb-4">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18"></h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to FaithX</li>
                </ol>
              </div>

            </div>
          </div>
        </Row>

      </div>
      <div className="container">
        <Row>
          <Col lg={12}>
            <Card style={{backgroundColor: "#283d92"}} className="text-white mt-md-2 mt-sm-4">
              <CardBody>
                <div className="row">
                  <div className="col-md-4 sm-12">
                    <h5 className="mt-0 mb-4 text-white">
                      <i className="mdi mdi-bullseye-arrow me-3"></i> Production Name
                    </h5>
                    <h2 className="text-white" style={{marginLeft: 30}}>{productionName}</h2>
                  </div>
                  <div className="col-md-4 sm-12">
                    <h5 className="mt-0 mb-4 text-white">
                      <i className="mdi mdi-bullseye-arrow me-3"></i> Number of Movies
                    </h5>
                    <h2 className="text-white" style={{marginLeft: 30}}>{numberOfMovies}</h2>
                  </div>
                  <div className="col-md-4 sm-12">
                    <h5 className="mt-0 mb-4 text-white">
                      <i className="mdi mdi-bullseye-arrow me-3"></i> Funds Raised
                    </h5>
                    <h2 className="text-white" style={{marginLeft: 30}}>{"$2,300"}</h2>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Card outline color="primary" className="border">
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-primary text-center">
                  <i className="uil uil-check-circle me-3"></i>Total Subscribers
                </h4>
                {/*<h2 className="my-0 text-success">*/}
                {/*  <i className="uil uil-check-circle me-3"></i>Deposits*/}
                {/*</h2>*/}
              </CardHeader>
              <CardBody>
                <CardTitle className="h5 mt-0 text-center"></CardTitle>
                <h2 className="text-center">
                  {"0"}
                </h2>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card outline color="danger" className="border">
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-danger text-center">
                  <i className="uil uil-check-circle me-3"></i>Active Subscribers
                </h4>
                {/*<h2 className="my-0 text-success">*/}
                {/*  <i className="uil uil-check-circle me-3"></i>Deposits*/}
                {/*</h2>*/}
              </CardHeader>
              <CardBody>
                <CardTitle className="h5 mt-0 text-center"></CardTitle>
                <h2 className="text-center">
                  {"0"}
                </h2>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card outline color="primary" className="border">
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-primary text-center">
                  <i className="uil uil-check-circle me-3"></i>Monthly Growth %
                </h4>
                {/*<h2 className="my-0 text-success">*/}
                {/*  <i className="uil uil-check-circle me-3"></i>Deposits*/}
                {/*</h2>*/}
              </CardHeader>
              <CardBody>
                <CardTitle className="h5 mt-0 text-center"></CardTitle>
                <h2 className="text-center">
                  {"0"}
                </h2>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Card outline color="primary" className="border">
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-primary text-center">
                  <i className="uil uil-check-circle me-3"></i>Total Watches
                </h4>
                {/*<h2 className="my-0 text-success">*/}
                {/*  <i className="uil uil-check-circle me-3"></i>Deposits*/}
                {/*</h2>*/}
              </CardHeader>
              <CardBody>
                <CardTitle className="h5 mt-0 text-center"></CardTitle>
                <h2 className="text-center">
                  {"0"}
                </h2>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card outline color="danger" className="border">
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-danger text-center">
                  <i className="uil uil-check-circle me-3"></i>Currently Watching
                </h4>
                {/*<h2 className="my-0 text-success">*/}
                {/*  <i className="uil uil-check-circle me-3"></i>Deposits*/}
                {/*</h2>*/}
              </CardHeader>
              <CardBody>
                <CardTitle className="h5 mt-0 text-center"></CardTitle>
                <h2 className="text-center">
                  {"0"}
                </h2>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card outline color="primary" className="border">
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-primary text-center">
                  <i className="uil uil-check-circle me-3"></i>Watches this Month
                </h4>
                {/*<h2 className="my-0 text-success">*/}
                {/*  <i className="uil uil-check-circle me-3"></i>Deposits*/}
                {/*</h2>*/}
              </CardHeader>
              <CardBody>
                <CardTitle className="h5 mt-0 text-center"></CardTitle>
                <h2 className="text-center">
                  {"0"}
                </h2>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
