import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import VerifyingEmail from "../pages/Authentication/VerifyingEmail";
import UserProfile from "../pages/Authentication/user-profile";
import Movies from "../pages/Dashboard/Movies";
import TVShow from "../pages/Dashboard/TVShow";
import Episodes from "../pages/Dashboard/Episodes";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/profile", component: UserProfile },
  { path: "/movies", component: Movies },
  { path: "/tvshows", component: TVShow },
  { path: "/episodes", component: Episodes },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/email/verify/:code", component: VerifyingEmail },

]

export { userRoutes, authRoutes }
