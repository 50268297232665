// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAqSWH3Zidw7to3j4X5KFCLu1KMfu_Q_mU",
    authDomain: "faithx-f2087.firebaseapp.com",
    projectId: "faithx-f2087",
    storageBucket: "faithx-f2087.appspot.com",
    messagingSenderId: "421255719108",
    appId: "1:421255719108:web:beb6ac0fa7ce561bb38191",
    measurementId: "G-2ZBZW4VBLZ"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
